const static_main = [
    {
        id: 'car',
        label: 'بازار خودرو',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار خودرو.png",
        index: 0
    },
    {
        id: 'car_accessories',
        label: 'بازار لوازم خودرو',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/خودرو.png",
        index: 1
    },
    {
        id: 'iron',
        label: 'بازار آهن آلات',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار آهن آلات.png",
        index: 2
    },
    {
        id: 'food',
        label: 'بازار مواد غذایی',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار مواد غذایی.png",
        index: 3
    },
    {
        id: 'home_appliances',
        label: 'بازار لوازم خانگی',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار لوازم خانگی.png",
        index: 4
    },
    {
        id: 'computer',
        label: 'بازار کامپیوتر',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار کامپیوتر.png",
        index: 5
    },
    {
        id: 'digital',
        label: 'بازار لوازم دیجیتال',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار لوازم دیجیتال.png",
        index: 6
    },
    {
        id: 'tools',
        label: 'بازار ابزار آلات',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار ابزارآلات.png",
        index: 7
    },
    {
        id: 'paper',
        label: 'بازار کاغذ',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار کاغذ.png",
        index: 8
    },
    {
        id: 'baby',
        label: 'بازار لوازم کودک و نوزاد',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار لوازم کودک و نوزاد.png",
        index: 9
    },
    {
        id: 'materials',
        label: 'بازار مصالح ساختمانی',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/مصالح ساختمان.png",
        index: 10
    },
    {
        id: 'entertainment',
        label: 'بازار لوازم سرگرمی',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/ورزش و سرگرمی.png",
        index: 11
    },
    {
        id: 'culture',
        label: 'بازار فرهنگ و هنر',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار فرهنگ و  هنر.png",
        index: 12
    },
    {
        id: 'clothing',
        label: 'بازار البسه، کیف و کفش',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار البسه، کیف و کفش.png",
        index: 13
    },
    {
        id: 'health',
        label: 'بازار پزشکی و سلامت',
        info: 'آخرین نرخ ها و نوسانات بازار',
        image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/زیبایی و سلامت.png",
        index: 14
    },
    // {
    //     id: 'decoration',
    //     label: 'بازار دکوراسیون ',
    //     info: 'آخرین نرخ ها و نوسانات بازار',
    //     image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/زیبایی و سلامت.png",
    //     index: 15
    // },
    // {
    //     id: 'petshop',
    //     label: 'بازار پت شاپ',
    //     info: 'آخرین نرخ ها و نوسانات بازار',
    //     image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/زیبایی و سلامت.png",
    //     index: 16
    // },
    // {
    //     id: 'travel',
    //     label: 'بازار تجهیزات سفر و کمپینگ ',
    //     info: 'آخرین نرخ ها و نوسانات بازار',
    //     image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/زیبایی و سلامت.png",
    //     index: 17
    // },
    // {
    //     id: 'other',
    //     label: 'سایر بازارها',
    //     info: 'آخرین نرخ ها و نوسانات بازار',
    //     image: "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/زیبایی و سلامت.png",
    //     index: 18
    // },

]
export default static_main;